import { type Action, combineReducers, configureStore, type ThunkAction } from '@reduxjs/toolkit'
import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import { createTransform, persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import * as Sentry from '@sentry/react'

import reducers from './reducers'

const rootReducer = combineReducers(reducers)

const removeLoadingTransform = createTransform(
  // eslint-disable-next-line
  (inboundState: any) => {
    if (inboundState && typeof inboundState === 'object') {
      const { loading, ...rest } = inboundState
      return rest
    }
    return inboundState
  },
  (outboundState: any) => outboundState, // eslint-disable-line
  { whitelist: [] }
)

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['connection', 'modal', 'theme', 'toasts'],
  transforms: [removeLoadingTransform]
}

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(Sentry.createReduxEnhancer({})),
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
  }
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
