import * as utils from 'denisonweb-utils'
import { parseISO } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { singular } from 'pluralize'

export * from 'denisonweb-utils'

export const camelToDashCase = (str: string): string => {
  return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase()
}

export const convertServiceToModelName = (serviceName: string): string => {
  return singular(utils.capitalizeFirstLetter(utils.kebabCaseToCamelCase(serviceName)))
}

export const dashToCamelCase = (str: string): string => {
  return str.replace(/-./g, (match) => match.charAt(1).toUpperCase())
}

export const dateToTime = (date: Date): string => {
  let hours = date.getHours()
  const minutes = date.getMinutes()
  const period = hours >= 12 ? 'PM' : 'AM'

  if (hours === 0) {
    hours = 12
  } else if (hours > 12) {
    hours -= 12
  }

  const hoursStr = hours.toString().padStart(2, '0')
  const minutesStr = minutes.toString().padStart(2, '0')

  return `${hoursStr}:${minutesStr} ${period}`
}

export const hasOverlap = (array1: string[], array2: string[]): boolean => {
  const set1 = new Set(array1)
  for (const element of array2) {
    if (set1.has(element)) {
      return true
    }
  }
  return false
}

export const formatDate = (utcDateString: string, dateFormat: string = 'MMMM d, yyyy h:mm a') => {
  const date = parseISO(utcDateString)
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const zonedDate = utcToZonedTime(date, userTimeZone)
  return format(zonedDate, dateFormat, { timeZone: userTimeZone })
}

export const formatDateShort = (utcDateString: string) => formatDate(utcDateString, 'MMM d, h:mm a')

export const timeToDate = (time: string): Date => {
  const [hoursAndMinutes, period] = time.split(' ')
  const [hours, minutes] = hoursAndMinutes.split(':')

  let hoursInt = parseInt(hours, 10)

  if (period.toUpperCase() === 'PM' && hoursInt !== 12) {
    hoursInt += 12
  } else if (period.toUpperCase() === 'AM' && hoursInt === 12) {
    hoursInt = 0
  }

  const date = new Date()
  date.setHours(hoursInt, parseInt(minutes, 10), 0, 0)

  return date
}
