import { createAsyncThunk, createSlice, type Action, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import { titleByFeatureName } from '../../../constants/features'

import type { DisabledFeature, DisabledFeatureData } from '../../../models/DisabledFeature'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

interface DataState {
  loading: boolean
  updating: boolean
  data: DisabledFeature[]
  errorMessage?: string
}

const initialState: DataState = {
  loading: false,
  updating: false,
  data: []
}

type ICreateParams = {
  data: DisabledFeatureData
  params?: Params
}

interface IRemoveParams {
  id: string
  feature: string
  params?: Params
}

const handleFulfilled = (loadingProp: 'loading' | 'updating') => (state: DataState, action: PayloadAction<DisabledFeature | DisabledFeature[] | void>) => {
  if (action.payload) {
    state.data = mergeData(state.data, action.payload)
  }
  state[loadingProp] = false
}

const handleRejected = (loadingProp: 'loading' | 'updating') => (state: DataState, action: Action<string> & { error: { message?: string } }) => {
  state.errorMessage = action.error.message
  state[loadingProp] = false
}

const handlePending = (loadingProp: 'loading' | 'updating') => (state: DataState) => {
  delete state.errorMessage
  state[loadingProp] = true
}

const handleRemoveFulfilled = (state: DataState, action: PayloadAction<DisabledFeature>) => {
  if (action.payload) {
    state.data = removeData(state.data, action.payload)
  }
  state.updating = false
}

export const findAsync = createAsyncThunk('disabled-features/find', async (params: Params | undefined, { dispatch }): Promise<DisabledFeature[]> => {
  try {
    const { data } = await Api.find('disabled-features', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting disabled-features: ${(e as Error).message}` }))
    throw e
  }
})

export const createAsync = createAsyncThunk(
  'disabled-features/create',
  async ({ data, params }: ICreateParams, { dispatch }): Promise<DisabledFeature | void> => {
    try {
      const result = await Api.create('disabled-features', data, params)
      dispatch(createToast({ type: 'success', message: `${titleByFeatureName[data.feature]} disabled successfully` }))
      return result
    } catch (e: unknown) {
      dispatch(createToast({ type: 'error', message: `Error creating disabled-feature: ${(e as Error).message}` }))
      throw e
    }
  }
)

export const removeAsync = createAsyncThunk(
  'disabled-features/remove',
  async ({ id, feature, params }: IRemoveParams, { dispatch }): Promise<DisabledFeature> => {
    try {
      const result = await Api.remove('disabled-features', id)
      dispatch(createToast({ type: 'success', message: `${titleByFeatureName[feature]} enabled successfully` }))
      return result
    } catch (e: unknown) {
      dispatch(createToast({ type: 'error', message: `Error deleting disabled-feature: ${(e as Error).message}` }))
      throw e
    }
  }
)

export const disabledFeaturesSlice = createSlice({
  name: 'disabled-features',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<DisabledFeature | DisabledFeature[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<DisabledFeature | DisabledFeature[]>) => {
      state.data = removeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending('loading'))
      .addCase(findAsync.fulfilled, handleFulfilled('loading'))
      .addCase(findAsync.rejected, handleRejected('loading'))
      .addCase(createAsync.pending, handlePending('updating'))
      .addCase(createAsync.fulfilled, handleFulfilled('updating'))
      .addCase(createAsync.rejected, handleRejected('updating'))
      .addCase(removeAsync.pending, handlePending('updating'))
      .addCase(removeAsync.fulfilled, handleRemoveFulfilled)
      .addCase(removeAsync.rejected, handleRejected('updating'))
  }
})

export const { dataAdded, dataRemoved } = disabledFeaturesSlice.actions

export default disabledFeaturesSlice.reducer
