import { lazy, type LazyExoticComponent, type ReactNode, Suspense } from 'react'
import { BrowserRouter, Route, Routes as RoutesContainer } from 'react-router-dom'

import { ScrollToTopView as ScrollToTop } from '../components/ScrollToTop'

const AcceptableUsePolicy = lazy(() => import(/* webpackPrefetch: true */ './AcceptableUsePolicy').then((m) => ({ default: m.AcceptableUsePolicyView })))
const AddPoll = lazy(() => import(/* webpackPrefetch: true */ './Dashboard/GuildDetail/Polls/Add').then((m) => ({ default: m.AddPollView })))
const AddReactionRole = lazy(() =>
  import(/* webpackPrefetch: true */ './Dashboard/GuildDetail/ReactionRoles/Add').then((m) => ({ default: m.AddReactionRoleView }))
)
const Checkout = lazy(() => import(/* webpackPrefetch: true */ './Checkout').then((m) => ({ default: m.CheckoutView })))
const CookiePolicy = lazy(() => import(/* webpackPrefetch: true */ './CookiePolicy').then((m) => ({ default: m.CookiePolicyView })))
const Dashboard = lazy(() => import(/* webpackPrefetch: true */ './Dashboard').then((m) => ({ default: m.DashboardView })))
const GuildDetail = lazy(() => import(/* webpackPrefetch: true */ './Dashboard/GuildDetail').then((m) => ({ default: m.GuildDetailView })))
const Home = lazy(() => import(/* webpackPrefetch: true */ './Home').then((m) => ({ default: m.HomeView })))
const LeavingMessage = lazy(() => import(/* webpackPrefetch: true */ './Dashboard/GuildDetail/LeavingMessage').then((m) => ({ default: m.LeavingMessageView })))
const Notifications = lazy(() => import(/* webpackPrefetch: true */ './Dashboard/GuildDetail/Notifications').then((m) => ({ default: m.NotificationsView })))
const Oauth = lazy(() => import(/* webpackPrefetch: true */ './Oauth').then((m) => ({ default: m.OauthView })))
const Permissions = lazy(() => import(/* webpackPrefetch: true */ './Dashboard/GuildDetail/Permissions').then((m) => ({ default: m.PermissionsView })))
const PersonalizedBot = lazy(() =>
  import(/* webpackPrefetch: true */ './Dashboard/GuildDetail/PersonalizedBot').then((m) => ({ default: m.PersonalizedBotView }))
)
const PollDetail = lazy(() => import(/* webpackPrefetch: true */ './Dashboard/GuildDetail/Polls/Detail').then((m) => ({ default: m.PollDetailView })))
const Polls = lazy(() => import(/* webpackPrefetch: true */ './Dashboard/GuildDetail/Polls').then((m) => ({ default: m.PollsView })))
const PrivacyPolicy = lazy(() => import(/* webpackPrefetch: true */ './PrivacyPolicy').then((m) => ({ default: m.PrivacyPolicyView })))
const QuoteOfTheDay = lazy(() => import(/* webpackPrefetch: true */ './Dashboard/GuildDetail/QuoteOfTheDay').then((m) => ({ default: m.QuoteOfTheDayView })))
const ReactionRoleDetail = lazy(() =>
  import(/* webpackPrefetch: true */ './Dashboard/GuildDetail/ReactionRoles/Detail').then((m) => ({ default: m.ReactionRoleDetailView }))
)
const ReactionRoles = lazy(() => import(/* webpackPrefetch: true */ './Dashboard/GuildDetail/ReactionRoles').then((m) => ({ default: m.ReactionRolesView })))
const RefundPolicy = lazy(() => import(/* webpackPrefetch: true */ './RefundPolicy').then((m) => ({ default: m.RefundPolicyView })))
const SecurityPolicy = lazy(() => import(/* webpackPrefetch: true */ './SecurityPolicy').then((m) => ({ default: m.SecurityPolicyView })))
const Settings = lazy(() => import(/* webpackPrefetch: true */ './Settings').then((m) => ({ default: m.SettingsView })))
const Subscription = lazy(() => import(/* webpackPrefetch: true */ './Subscription').then((m) => ({ default: m.SubscriptionView })))
const Subscriptions = lazy(() => import(/* webpackPrefetch: true */ './Subscriptions').then((m) => ({ default: m.SubscriptionsView })))
const TermsAndConditions = lazy(() => import(/* webpackPrefetch: true */ './TermsAndConditions').then((m) => ({ default: m.TermsAndConditionsView })))
const TiktokIntegration = lazy(() =>
  import(/* webpackPrefetch: true */ './Dashboard/GuildDetail/TiktokIntegration').then((m) => ({ default: m.TiktokIntegrationView }))
)
const TwitchIntegration = lazy(() =>
  import(/* webpackPrefetch: true */ './Dashboard/GuildDetail/TwitchIntegration').then((m) => ({ default: m.TwitchIntegrationView }))
)
const WelcomeMessage = lazy(() => import(/* webpackPrefetch: true */ './Dashboard/GuildDetail/WelcomeMessage').then((m) => ({ default: m.WelcomeMessageView })))
const YoutubeIntegration = lazy(() =>
  import(/* webpackPrefetch: true */ './Dashboard/GuildDetail/YoutubeIntegration').then((m) => ({ default: m.YoutubeIntegrationView }))
)

import type { Page } from './pages'
import pages from './pages'

const pageElements: Record<string, LazyExoticComponent<() => JSX.Element>> = {
  AcceptableUsePolicy,
  AddPoll,
  AddReactionRole,
  Checkout,
  CookiePolicy,
  Dashboard,
  GuildDetail,
  Home,
  LeavingMessage,
  Notifications,
  Oauth,
  Permissions,
  PersonalizedBot,
  PollDetail,
  Polls,
  PrivacyPolicy,
  QuoteOfTheDay,
  ReactionRoleDetail,
  ReactionRoles,
  RefundPolicy,
  SecurityPolicy,
  Settings,
  Subscription,
  Subscriptions,
  TermsAndConditions,
  TiktokIntegration,
  TwitchIntegration,
  WelcomeMessage,
  YoutubeIntegration
}

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<div></div>}>
        <RoutesContainer>
          {pages.map(({ path, elementName }: Page): ReactNode => {
            const Element = pageElements[elementName]
            return <Route key={path} path={path} element={<Element />} />
          })}
        </RoutesContainer>
      </Suspense>
    </BrowserRouter>
  )
}

export default Routes
