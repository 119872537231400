import { createAsyncThunk, createSlice, type Action, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { Notification, NotificationData, NotificationPatch } from '../../../models/Notification'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

interface DataState {
  loading: boolean
  data: Notification[]
  errorMessage?: string
}

const initialState: DataState = {
  loading: false,
  data: []
}

type ICreateParams = {
  data: NotificationData
  params?: Params
}

interface IPatchParams {
  id: string
  data: NotificationPatch
  params?: Params
}

interface IRemoveParams {
  id: string
  params?: Params
}

const handleFulfilled = (state: DataState, action: PayloadAction<Notification | Notification[]>) => {
  if (action.payload) {
    state.data = mergeData(state.data, action.payload)
  }
  state.loading = false
}

const handleRejected = (state: DataState, action: Action<string> & { error: { message?: string } }) => {
  state.errorMessage = action.error.message
  state.loading = false
}

const handlePending = (state: DataState) => {
  delete state.errorMessage
  state.loading = true
}

const handleRemoveFulfilled = (state: DataState, action: PayloadAction<Notification>) => {
  if (action.payload) {
    state.data = removeData(state.data, action.payload)
  }
  state.loading = false
}

export const findAsync = createAsyncThunk('notifications/find', async (params: Params | undefined, { dispatch }): Promise<Notification[]> => {
  try {
    const { data } = await Api.find('notifications', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting notifications: ${(e as Error).message}` }))
    throw e
  }
})

export const createAsync = createAsyncThunk('notifications/create', async ({ data, params }: ICreateParams, { dispatch }): Promise<Notification> => {
  try {
    return await Api.create('notifications', data, params)
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error creating notification: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk('notifications/patch', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<Notification> => {
  try {
    return await Api.patch('notifications', id, data, params)
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: (e as Error).message }))
    throw e
  }
})

export const removeAsync = createAsyncThunk('notifications/remove', async ({ id, params }: IRemoveParams, { dispatch }): Promise<Notification> => {
  try {
    const result = await Api.remove('notifications', id, params)
    dispatch(createToast({ type: 'success', message: 'Notification removed successfully' }))
    return result
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error deleting notification: ${(e as Error).message}` }))
    throw e
  }
})

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<Notification | Notification[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<Notification | Notification[]>) => {
      state.data = removeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending)
      .addCase(findAsync.fulfilled, handleFulfilled)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, handlePending)
      .addCase(patchAsync.fulfilled, handleFulfilled)
      .addCase(patchAsync.rejected, handleRejected)
      .addCase(createAsync.pending, handlePending)
      .addCase(createAsync.fulfilled, handleFulfilled)
      .addCase(createAsync.rejected, handleRejected)
      .addCase(removeAsync.pending, handlePending)
      .addCase(removeAsync.fulfilled, handleRemoveFulfilled)
      .addCase(removeAsync.rejected, handleRejected)
  }
})

export const { dataAdded, dataRemoved } = notificationsSlice.actions

export default notificationsSlice.reducer
