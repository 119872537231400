import { v4 as uuidv4 } from 'uuid'

import { environment } from './feathers/config'

type OAuthProvider = 'twitch' | 'youtube'

export const openDiscordBotOauthUrl = (guildId: string): Window => {
  sessionStorage.setItem('oauthOrigin', window.location.origin)
  const url =
    environment === 'development'
      ? `https://discord.com/api/oauth2/authorize?client_id=1096083708206915715&permissions=8&scope=bot%20applications.commands&guild_id=${guildId}&disable_guild_select=true`
      : environment === 'staging'
        ? `https://discord.com/api/oauth2/authorize?client_id=1172673412112588851&permissions=8&scope=bot%20applications.commands&guild_id=${guildId}&disable_guild_select=true`
        : `https://discord.com/api/oauth2/authorize?client_id=1096083708206915715&permissions=8&scope=bot%20applications.commands&guild_id=${guildId}&disable_guild_select=true`
  return window.open(url, '_blank', 'height=800,width=500')!
}

export const openPersonalizedDiscordBotOauthUrl = (guildId: string, clientId: string): Window => {
  const url = `https://discord.com/oauth2/authorize?scope=bot%20applications.commands&permissions=8&client_id=${clientId}&guild_id=${guildId}&disable_guild_select=true`
  return window.open(url, '_blank', 'height=800,width=500')!
}

export const openDiscordLoginOauthUrl = (): Window => {
  const clientId = encodeURIComponent(
    environment === 'development' ? '1096083708206915715' : environment === 'staging' ? '1172673412112588851' : '1096083708206915715'
  )
  const redirectUri = encodeURIComponent(
    environment === 'development'
      ? 'https://denisonweb-local-development.com/oauth'
      : environment === 'staging'
        ? 'https://staging.rt.ninja/oauth'
        : 'https://rt.ninja/oauth'
  )
  const scope = encodeURIComponent('guilds guilds.members.read identify messages.read')
  const state = uuidv4()
  sessionStorage.setItem('oauthState', state)
  sessionStorage.setItem('oauthOrigin', window.location.origin)
  const url = `https://discord.com/api/oauth2/authorize?state=${state}&client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`
  return window.open(url, '_blank', 'height=800,width=500')!
}

export const openSocialOauthUrl = (provider: OAuthProvider): Window | undefined => {
  const state = uuidv4()
  const redirectUri = encodeURIComponent(
    environment === 'development'
      ? 'https://denisonweb-local-development.com/oauth'
      : environment === 'staging'
        ? 'https://staging.rt.ninja/oauth'
        : 'https://rt.ninja/oauth'
  )

  sessionStorage.setItem('oauthState', state)
  sessionStorage.setItem('oauthOrigin', window.location.origin)

  if (provider === 'twitch') {
    const clientId = encodeURIComponent(
      environment === 'development'
        ? 'mr8452tv163kxn37wjyxc8njr1x5jd'
        : environment === 'staging'
          ? 'o79vw0ttc27eogcjqjcbrfgo23oh22'
          : 'tcvu3snldlj8vjjjwvntlvqvs8kq6r'
    )
    const scope = encodeURIComponent('channel:read:subscriptions')

    const url = `https://id.twitch.tv/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}`
    return window.open(url, '_blank', 'height=800,width=500')!
  }

  if (provider === 'youtube') {
    const clientId = encodeURIComponent(
      environment === 'staging'
        ? '730007206734-n12319fln5upn99gmeisi7cus4e5c6aq.apps.googleusercontent.com'
        : '730007206734-jbiejmghvnpp5shg61uedh2santl13tp.apps.googleusercontent.com'
    )
    const scope = encodeURIComponent('https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/youtube.readonly')

    const url = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&access_type=offline&state=${state}`
    return window.open(url, '_blank', 'height=800,width=500')!
  }
}
