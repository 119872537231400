import type { Action, PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { ReactionRole, ReactionRoleData, ReactionRolePatch } from '../../../models/ReactionRole'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

interface DataState {
  loading: boolean
  data: ReactionRole[]
  errorMessage?: string
}

const initialState: DataState = {
  loading: false,
  data: []
}

type ICreateParams = {
  data: ReactionRoleData
  params?: Params
}

type IPatchParams = {
  id: string
  data: ReactionRolePatch
  params?: Params
}

const handleFulfilled = (state: DataState, action: PayloadAction<ReactionRole | ReactionRole[] | void>) => {
  if (action.payload) {
    state.data = mergeData(state.data, action.payload)
  }
  state.loading = false
}

const handlePending = (state: DataState) => {
  delete state.errorMessage
  state.loading = true
}

const handleRejection = (state: DataState, action: Action<string> & { error: { message?: string } }) => {
  state.errorMessage = action.error.message
  state.loading = false
}

const handleRemoveFulfilled = (state: DataState, action: PayloadAction<ReactionRole>) => {
  if (action.payload) {
    state.data = removeData(state.data, action.payload)
  }
  state.loading = false
}

export const findAsync = createAsyncThunk('reaction-roles/find', async (params: Params, { dispatch }): Promise<ReactionRole[]> => {
  try {
    const { data } = await Api.find('reaction-roles', params)
    return data
  } catch (e: unknown) {
    dispatch(
      createToast({
        type: 'error',
        message: `Error getting reaction-roles: ${(e as Error).message}`
      })
    )
    throw e
  }
})

export const createAsync = createAsyncThunk('reaction-roles/create', async ({ data, params }: ICreateParams, { dispatch }): Promise<ReactionRole | void> => {
  try {
    return await Api.create('reaction-roles', data, params)
  } catch (e: unknown) {
    dispatch(
      createToast({
        type: 'error',
        message: `Error creating reaction role: ${(e as Error).message}`
      })
    )
    throw e
  }
})

export const patchAsync = createAsyncThunk('reaction-roles/update', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<ReactionRole> => {
  try {
    return await Api.patch('reaction-roles', id, data, params)
  } catch (e: unknown) {
    dispatch(
      createToast({
        type: 'error',
        message: `Error updating reaction role: ${(e as Error).message}`
      })
    )
    throw e
  }
})

export const removeAsync = createAsyncThunk('reaction-roles/remove', async (id: string, { dispatch }): Promise<ReactionRole> => {
  try {
    const result = await Api.remove('reaction-roles', id)
    dispatch(createToast({ type: 'success', message: 'ReactionRole removed successfully' }))
    return result
  } catch (e: unknown) {
    dispatch(
      createToast({
        type: 'error',
        message: `Error deleting reaction role: ${(e as Error).message}`
      })
    )
    throw e
  }
})

const reactionRolesSlice = createSlice({
  name: 'reaction-roles',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<ReactionRole | ReactionRole[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<ReactionRole | ReactionRole[]>) => {
      state.data = removeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending)
      .addCase(findAsync.fulfilled, handleFulfilled)
      .addCase(findAsync.rejected, handleRejection)
      .addCase(createAsync.pending, handlePending)
      .addCase(createAsync.fulfilled, handleFulfilled)
      .addCase(createAsync.rejected, handleRejection)
      .addCase(patchAsync.pending, handlePending)
      .addCase(patchAsync.fulfilled, handleFulfilled)
      .addCase(patchAsync.rejected, handleRejection)
      .addCase(removeAsync.pending, handlePending)
      .addCase(removeAsync.fulfilled, handleRemoveFulfilled)
      .addCase(removeAsync.rejected, handleRejection)
  }
})

export const { dataAdded, dataRemoved } = reactionRolesSlice.actions

export default reactionRolesSlice.reducer
